window.etoile = (window.etoile || {})

etoile.Rooms = (function Rooms($) {

  'use strict';

  /**
   * jQuery elements.
   * @private
   */
  var $roomBtn = $('.room-selection');
  var $roomsInfosWrapper = $('.rooms-information');
  var $roomInfo = $('.room-information');

  var selectedClassName = 'selected';

  /**
   * Has the application been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function () {

    // Abort if already initialized
    if (inited) {
      return false;
    }


    /**
     * Call to open the menu
     */

    $roomBtn.on('click', function (e) {
      var $this = $(this);
      toggleRoomInformation($this);
    });

    inited = true;

    // return success
    return true;

  };

  /**
   * Open / toggle room information according to selected room
   * @private
   */

  var toggleRoomInformation = function (e) {
    if (e.hasClass(selectedClassName)) {
      return false;
    }
    else {

      // Open room information corresponding to selected tile
      $roomBtn.removeClass(selectedClassName);

      e.addClass(selectedClassName);

      var selectedId = e.data('room-selection-id');

      $roomInfo.removeClass(selectedClassName);
      setTimeout(function() {
        $roomsInfosWrapper
          .find("[data-room-information-id='" + selectedId + "']")
          .addClass(selectedClassName);
      }, 1000);
    }
  };


  // Expose public methods & properties
  return {
    init: init,
  };

})(jQuery);

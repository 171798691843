window.etoile = (window.etoile || {});

/**
 * Utilities for submitting forms through ajax.
 * @class Form
 * @static
 */
etoile.Form = (function Form($) {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Contact forms submit events.
    $('form[data-form="contest"]').on('submit', function(event) {
      event.preventDefault();
      submitContact($(this));
    });

    return true;
  };

  /**
   * Submit contact forms.
   * @private
   */
  var submitContact = function($form) {

    var data;
    var $button = $('button[type="submit"]', $form);

    // Disable submit button.
    $button.prop('disabled', true);

    // Reset fields errors.
    $('input, textarea, select', $form).removeClass('error');

    // Serialize data and append csrf token.
    data = $form.serialize();
    data += '&etoile_csrf=' + encodeURIComponent(etoile.App.getConfig('csrf'));

    // Submit the form through ajax post.
    $.ajax({ url: '/actions/contact/saveUser', data: data, type: 'POST', cache: false,

      success: function(response) {

        var focus = false;

        if (response.success && response.success === true) {

          // Trigger successful submitted form.
          $form.trigger('form.submitted');

          // Reset form fields and enable submit button.
          setTimeout(function() {
            $form.trigger('reset');
            $button.prop('disabled', false);
          }, 1000);


          $('.js-success').fadeIn(300).delay(1000).fadeOut(300);

          // Return success.
          return true;

        } else if (response.errors) {

          // Highlight fields with errors and focus the first.
          $.each(response.errors, function(index) {

            var $field = $('[name="' + index + '"]', $form);

            if ($field.length) {
              $field.addClass('error');

              if (focus === false) {
                $field.focus();
                focus = true;
              }
            }

          });

          // Enable submit button.
          setTimeout(function() {
            $button.prop('disabled', false);
          }, 1000);
        }

        // Return failure.
        return false;

      },

      error: function() {

        // Enable submit button.
        setTimeout(function() {
          $button.prop('disabled', false);
        }, 1000);

        alert('Une erreur est survenue! Impossible d\'envoyé le formulaire.');

      },

    });

  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init,
  };

})(jQuery);
